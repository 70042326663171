import { Suspense, lazy } from 'react';
import {
  Routes, Route, BrowserRouter, Navigate,
} from 'react-router-dom';

const HomePage = lazy(
  () => import(/* webpackChunkName: "home" */ 'pages/home/HomePage'),
);
const Privacy = lazy(
  () => import(/* webpackChunkName: "privacy" */ 'pages/privacy/Privacy'),
);
const Tos = lazy(
  () => import(/* webpackChunkName: "tos" */ 'pages/tos/Tos'),
);
const TosGame = lazy(
  () => import(/* webpackChunkName: "tosGame" */ 'pages/tosGame/TosGame'),
);
const Support = lazy(
  () => import(/* webpackChunkName: "support" */ 'pages/support/Support'),
);

const Router = () => (
  <BrowserRouter>
    <Suspense fallback={<div />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/tos" element={<Tos />} />
        <Route path="/tos-game" element={<TosGame />} />
        <Route path="/support" element={<Support />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default Router;
