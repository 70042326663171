import { createGlobalStyle } from 'styled-components';

import ddc from 'assets/fonts/ddc.otf';
import violence from 'assets/fonts/Violence.ttf';
import openSans from 'assets/fonts/OpenSans-Regular.ttf';
import montserrat from 'assets/fonts/Montserrat-Regular.ttf';

/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'DDCHardware';
    src: url(${ddc}) format('opentype');
  }
  @font-face {
    font-family: 'violence';
    src: url(${violence}) format('opentype');
  }
  @font-face {
    font-family: 'Open Sans';
    src: url(${openSans}) format('opentype');
  }

  @font-face {
    font-family: 'montserrat';
    src: url(${montserrat}) format('opentype');
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
  }
  html, body {
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    background: #1c1c22;

    @media (max-height: 895px), (max-width: 1300px) {
      font-size: 10px;
    }
  }
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  input, button, textarea, select {
    font: inherit;
  }
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  #root {
    isolation: isolate;
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #1c1c22;
    border-radius: 0.7rem;
  }

  ::-webkit-scrollbar-thumb {
    background: #ff2d7e;
    border-radius: 0.7rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #AE1D55;
  }
`;

export default GlobalStyle;
