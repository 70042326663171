import { Layout } from 'components/common/Layout';

interface IErrorFallbackProps {
  resetErrorBoundary(): void;
}

const ErrorPage = ({ resetErrorBoundary }: IErrorFallbackProps) => (
  <Layout>
    <h1>
      Something went wrong
    </h1>
    <button type="button" onClick={resetErrorBoundary}>Try Again</button>
  </Layout>
);

export default ErrorPage;
