const theme = {
  colors: {
    white: '#ffffff',
    black: '#222222',
    darkGrey: '#434343',
    grey: '#959595',
    pink: '#f52776',
    darkPink: '#d5006a',
    darkBlue: '#030025',
    slate: '#1F1F1F',
  },
  gradients: {
    slate: 'linear-gradient(#30323c, #18191e)',
    silver: 'linear-gradient(#e1e1e1, #686868)',
    silverInvert: 'linear-gradient(360deg, #e1e1e1, #686868)',
  },
  breakpoints: {
    mobile: '(max-width: 685px), (max-height: 600px)',
    tablet: '(max-width: 1000px), (max-height: 630px)',
  },
};

export default theme;
