import { QueryClient, QueryClientProvider } from 'react-query';

import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';

import Router from 'Router';

import { ThemeProvider } from 'styled-components';

import ErrorPage from 'pages/ErrorPage';

import theme from './theme';

const oneHourInMiliseconds = 1000 * 60 * 60;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: 'always',
      refetchInterval: oneHourInMiliseconds,
      staleTime: oneHourInMiliseconds,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Router />
        </ErrorBoundary>
      </HelmetProvider>
    </ThemeProvider>
  </QueryClientProvider>
);

export default App;
